import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from '../components/LoadingSpinner';
import './Banners.css';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EvStationIcon from '@mui/icons-material/EvStation';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoneyIcon from '@mui/icons-material/Money';
import { ReactComponent as RolletLogo } from '../images/rollet.svg';
import Tooltip from '@mui/material/Tooltip';

const Banners = props => {
    const { isLoading, sendRequest } = useHttpClient();
    const [loadedOccupancy, setLoadedOccupancy] = useState();
    const countAvailable = props.items.data.evses.reduce((acc, data) => {
        return acc + (data.status === 'AVAILABLE' ? 1 : 0);
    }, 0);

    useEffect(() => {
        const fetchRequest = async () => {
            const options = {
                url:  `./occupancy_data.php`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseOccupancyData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedOccupancy(responseOccupancyData);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest]);

    return(
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            <div className='banners'>

                <div className='banner-col'>
                    <div className='banner-top-title'>
                        <div className='banner-row1'>
                            <div className='orange-line' style={{marginTop:'5px',marginBottom:'10px'}}></div>
                            <span>Sas utcai mélygarázs</span>
                        </div>
                    </div>
                        <div className='banner-info'>
                            <div className='banner-top'>
                                <div className='banner-row2' style={{marginTop:'10px'}}>
                                    <div className='banner-inner1'>Fizetési lehetőségek</div>
                                    <div className='banner-inner1' style={{margin:'5px 0'}}>
                                        <div className='banner-inner2'><Tooltip title="QR-kódos online fizetés"><QrCodeIcon /></Tooltip></div>
                                        <div className='banner-inner2'><Tooltip title="Rollet"><RolletLogo /></Tooltip></div>
                                        <div className='banner-inner2'><Tooltip title="Bankkártyás fizetés"><CreditCardIcon /></Tooltip></div>
                                        <div className='banner-inner2'><Tooltip title="Készpénzes fizetés"><MoneyIcon /></Tooltip></div>
                                    </div>
                                </div>
                                <div className='dot'>·</div>
                                <div className='banner-row2'>
                                    <div className='banner-inner1'>Szabad férőhelyek&nbsp;/&nbsp;összes (valós&nbsp;idejű)*</div>
                                    <div className='banner-inner1'>
                                        <div className='banner-inner2'><DirectionsCarIcon /></div>
                                        <div className='banner-inner2'>{loadedOccupancy && (loadedOccupancy.areas[4].capacity - loadedOccupancy.areas[4].occupied)} / {loadedOccupancy && loadedOccupancy.areas[4].capacity}</div>
                                    </div>
                                </div>
                                <div className='dot'>·</div>
                                <div className='banner-row2'>
                                    <div className='banner-inner1'>Elektromos töltők (valós&nbsp;idejű)*</div>
                                    <div className='banner-inner1'>
                                        <div className='banner-inner2'><EvStationIcon /></div>
                                        <div className='banner-inner2'>{countAvailable}&nbsp;/&nbsp;20</div>
                                    </div>
                                </div>
                                <div className='dot'>·</div>
                                <div className='banner-row2'>
                                    <div className='banner-inner1'>Magasságkorlátozás</div>
                                    <div className='banner-inner1'>
                                        <div className='banner-inner2'><LocalShippingIcon /></div>
                                        <div className='banner-inner2'>2,1m</div>
                                    </div>
                                </div>
                            </div>
                            <div className='banner-bottom'>
                                <div className='banner-row2'>
                                    <div className='banner-inner1'>
                                        <a href='sas-utcai-melygarazs'className='orange-button' style={{padding: '3px 15px',cursor: 'pointer'}}>Részletek</a>
                                    </div>
                                </div>
                                <div className='banner-row2' style={{marginBottom:'10px'}}>
                                    <div className='banner-inner3'><span>*A valós idejű adataink tájékoztató jellegűek</span></div>
                                </div>
                            </div>
                        </div>
                </div>

                <div className='banner-col'>
                    <div className='banner-top-title'>
                        <div className='banner-row1'>
                            <div className='orange-line' style={{marginTop:'5px',marginBottom:'10px'}}></div>
                            Hal köz mélygarázs
                        </div>
                    </div>
                    <div className='banner-info'>
                        <div className='banner-top'>
                            <div className='banner-row2' style={{marginTop:'10px'}}>
                                <div className='banner-inner1'>Fizetési lehetőségek</div>
                                <div className='banner-inner1' style={{margin:'5px 0'}}>
                                    <div className='banner-inner2'><Tooltip title="Rollet"><RolletLogo /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Bankkártyás fizetés"><CreditCardIcon /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Készpénzes fizetés"><MoneyIcon /></Tooltip></div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Szabad férőhelyek&nbsp;/&nbsp;összes (valós&nbsp;idejű)*</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><DirectionsCarIcon /></div>
                                    <div className='banner-inner2'>{loadedOccupancy && (loadedOccupancy.areas[3].capacity - loadedOccupancy.areas[3].occupied)} / {loadedOccupancy && loadedOccupancy.areas[3].capacity}</div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Magasságkorlátozás</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><LocalShippingIcon /></div>
                                    <div className='banner-inner2'>1,85m | 1,8m</div>
                                </div>
                            </div>
                        </div>
                        <div className='banner-bottom'>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>
                                    <a href='halkoz-melygarazs' className='orange-button' style={{padding: '3px 15px',cursor: 'pointer'}}>Részletek</a>
                                </div>
                            </div>
                            <div className='banner-row2' style={{marginBottom:'10px'}}>
                                <div className='banner-inner3'><span>*A valós idejű adataink tájékoztató jellegűek</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='banner-col'>
                    <div className='banner-top-title'>
                        <div className='banner-row1'>
                            <div className='orange-line' style={{marginTop:'5px',marginBottom:'10px'}}></div>
                            Bethlen utcai mélygarázs
                        </div>
                    </div>
                    <div className='banner-info'>
                        <div className='banner-top'>
                            <div className='banner-row2' style={{marginTop:'10px'}}>
                                <div className='banner-inner1'>Fizetési lehetőségek</div>
                                <div className='banner-inner1' style={{margin:'5px 0'}}>
                                    <div className='banner-inner2'><Tooltip title="Rollet"><RolletLogo /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Bankkártyás fizetés"><CreditCardIcon /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Készpénzes fizetés"><MoneyIcon /></Tooltip></div>                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Szabad férőhelyek&nbsp;/&nbsp;összes (valós&nbsp;idejű)*</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><DirectionsCarIcon /></div>
                                    <div className='banner-inner2'>{loadedOccupancy && (loadedOccupancy.areas[1].capacity - loadedOccupancy.areas[1].occupied)} / {loadedOccupancy && loadedOccupancy.areas[1].capacity}</div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Magasságkorlátozás</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><LocalShippingIcon /></div>
                                    <div className='banner-inner2'>1,9m</div>
                                </div>
                            </div>
                        </div>
                        <div className='banner-bottom'>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>
                                    <a href='bethlen-utcai-melygarazs'className='orange-button' style={{padding: '3px 15px',cursor: 'pointer'}}>Részletek</a>
                                </div>
                            </div>
                            <div className='banner-row2' style={{marginBottom:'10px'}}>
                                <div className='banner-inner3'><span>*A valós idejű adataink tájékoztató jellegűek</span></div>
                            </div>                        
                        </div>
                    </div>
                </div>

                <div className='banner-col'>
                    <div className='banner-top-title'>
                        <div className='banner-row1'>
                            <div className='orange-line' style={{marginTop:'5px',marginBottom:'10px'}}></div>
                            Kölcsey központ mélygarázs
                        </div>
                    </div>
                    <div className='banner-info'>
                        <div className='banner-top'>
                            <div className='banner-row2' style={{marginTop:'10px'}}>
                                <div className='banner-inner1'>Fizetési lehetőségek</div>
                                <div className='banner-inner1' style={{margin:'5px 0'}}>
                                    <div className='banner-inner2'><Tooltip title="Rollet"><RolletLogo /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Bankkártyás fizetés"><CreditCardIcon /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Készpénzes fizetés"><MoneyIcon /></Tooltip></div>                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                            <div className='banner-inner1'>Szabad férőhelyek&nbsp;/&nbsp;összes (valós&nbsp;idejű)*</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><DirectionsCarIcon /></div>
                                    <div className='banner-inner2'>{loadedOccupancy && (loadedOccupancy.areas[0].capacity - loadedOccupancy.areas[0].occupied)} / {loadedOccupancy && loadedOccupancy.areas[0].capacity}</div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Magasságkorlátozás</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><LocalShippingIcon /></div>
                                    <div className='banner-inner2'>2,1m | 1,8m</div>
                                </div>
                            </div>
                        </div>
                        <div className='banner-bottom'>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>
                                    <a href='kolcsey-kozpont-melygarazs'className='orange-button' style={{padding: '3px 15px',cursor: 'pointer'}}>Részletek</a>
                                </div>
                            </div>
                            <div className='banner-row2' style={{marginBottom:'10px'}}>
                                <div className='banner-inner3'>
                                    <span>*A valós idejű adataink tájékoztató jellegűek</span>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>

                <div className='banner-col'>
                    <div className='banner-top-title'>
                        <div className='banner-row1'>
                            <div className='orange-line' style={{marginTop:'5px',marginBottom:'10px'}}></div>
                            Ítélőtábla mélygarázs
                        </div>
                    </div>
                    <div className='banner-info'>
                        <div className='banner-top'>
                            <div className='banner-row2' style={{marginTop:'10px'}}>
                                <div className='banner-inner1'>Fizetési lehetőségek</div>
                                <div className='banner-inner1' style={{margin:'5px 0'}}>
                                    <div className='banner-inner2'><Tooltip title="Rollet"><RolletLogo /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Bankkártyás fizetés"><CreditCardIcon /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Készpénzes fizetés"><MoneyIcon /></Tooltip></div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                            <div className='banner-inner1'>Szabad férőhelyek&nbsp;/&nbsp;összes (valós&nbsp;idejű)*</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><DirectionsCarIcon /></div>
                                    <div className='banner-inner2'>{loadedOccupancy && (loadedOccupancy.areas[2].capacity - loadedOccupancy.areas[2].occupied)} / {loadedOccupancy && loadedOccupancy.areas[2].capacity}</div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Magasságkorlátozás</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><LocalShippingIcon /></div>
                                    <div className='banner-inner2'>2,1m</div>
                                </div>
                            </div>
                        </div>
                        <div className='banner-bottom'>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>
                                    <a href='itelotabla-melygarazs'className='orange-button' style={{padding: '3px 15px',cursor: 'pointer'}}>Részletek</a>
                                </div>
                            </div>
                            <div className='banner-row2' style={{marginBottom:'10px'}}>
                                <div className='banner-inner3'>
                                    <span>*A valós idejű adataink tájékoztató jellegűek</span>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>

                <div className='banner-col'>
                    <div className='banner-top-title'>
                        <div className='banner-row1'>
                            <div className='orange-line' style={{marginTop:'5px',marginBottom:'10px'}}></div>
                            Nagyerdei mélygarázs
                        </div>
                    </div>
                    <div className='banner-info'>
                        <div className='banner-top'>
                            <div className='banner-row2' style={{marginTop:'10px'}}>
                                <div className='banner-inner1'>Fizetési lehetőségek</div>
                                <div className='banner-inner1' style={{margin:'5px 0'}}>
                                    <div className='banner-inner2'><Tooltip title="Rollet"><RolletLogo /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Bankkártyás fizetés"><CreditCardIcon /></Tooltip></div>
                                    <div className='banner-inner2'><Tooltip title="Készpénzes fizetés"><MoneyIcon /></Tooltip></div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Férőhelyek összesen</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><DirectionsCarIcon /></div>
                                    <div className='banner-inner2'>154</div>
                                </div>
                            </div>
                            <div className='dot'>·</div>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>Magasságkorlátozás</div>
                                <div className='banner-inner1'>
                                    <div className='banner-inner2'><LocalShippingIcon /></div>
                                    <div className='banner-inner2'>2,1m</div>
                                </div>
                            </div>
                        </div>
                        <div className='banner-bottom'>
                            <div className='banner-row2'>
                                <div className='banner-inner1'>
                                    <a href='nagyerdei-melygarazs'className='orange-button' style={{padding: '3px 15px',cursor: 'pointer'}}>Részletek</a>
                                </div>
                            </div>
                            <div className='banner-row2' style={{marginBottom:'10px'}}>
                                <div className='banner-inner3'><span></span></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}
export default Banners;